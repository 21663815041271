<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
.app {
  position: relative;
  width: 3840px;
  height: 2160px;
  box-sizing: border-box;
  padding-bottom: 35px;
  background: url(@/assets/images/beijing.png) no-repeat;
  overflow: hidden;
  font-size: 30px;
  background-color: #020308;
  overflow: hidden;
}
</style>
